import { createRouter, createWebHistory } from 'vue-router';
import Layout from '../views/layout/Layout'
import CurrentLayout from '../views/current/currentLayout/index'
import DetailLayout from '../views/detail/layout'


export const constantRouterMap = [
  { path: '/login', component: () => import('@/views/login/index'), hidden: true },
  { path: '/404', component: () => import('@/views/404'), hidden: true },
  
  {
    path: '',
    component: Layout,
    redirect: '/home',
    meta: { keepAlive: true },
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/home/index'),
        meta: { title: '首页', icon: 'home', keepAlive: true }
      },
      {
        path: '/draw',
        name: 'draw',
        component: () => import('@/views/draw/index'),
        meta: { title: '临摹画图', icon: 'draw', keepAlive: true }
      },
      {
        path: '/photography',
        name: 'photography',
        component: () => import('@/views/photography/index'),
        meta: { title: '摄影实拍', icon: 'photography', keepAlive: true }
      },
      {
        path: '/videoDetail',
        name: 'videoDetail',
        component: () => import('@/views/video/videoDetail'),
        meta: { title: '视频详情', icon: 'videoDetail', keepAlive: true }
      },
      {
        path: '/video',
        name: 'video',
        component: () => import('@/views/video/index'),
        meta: { title: '视频实拍', icon: 'video', keepAlive: true }
      },
      {
        path: '/cameraDownload',
        name: 'cameraDownload',
        component: () => import('@/views/cameraDownload/index'),
        meta: { title: '镜头下载', icon: 'video', keepAlive: true }
      },
      {
        path: '/detail',
        name: 'detail',
        component: DetailLayout,
        redirect: '/detail/detailHome',
        meta: { title: '详情设计', icon: 'detail', keepAlive: true },
        children: [
          {
            path: 'detailHome',
            name: 'detailHome',
            component: () => import('@/views/detail/detailHome'),
            meta: { title: '详情首页', icon: 'generic', keepAlive: true },
          },
          {
            path: 'detailTemplate',
            name: 'detailTemplate',
            component: () => import('@/views/detail/detailTemplate'),
            meta: { title: '详情页模板', icon: 'generic', keepAlive: true },
          },
          {
            path: 'cameraHole',
            name: 'cameraHole',
            component: () => import('@/views/detail/cameraHole'),
            meta: { title: '镜头效果图', icon: 'generic', keepAlive: true },
          },
          {
            path: 'detailCustomized',
            name: 'detailCustomized',
            component: () => import('@/views/detail/detailCustomized'),
            meta: { title: '详情页定制', icon: 'generic', keepAlive: true },
          },
        ]
      },
      {
        path: '/original',
        name: 'original',
        component: () => import('@/views/original/index'),
        meta: { title: '原创图库', icon: 'original'}
      },
      {
        path: '/current',
        name: 'current',
        component: CurrentLayout,
        redirect: '/current/generic',
        meta: { title: '现图图库', icon: 'current', keepAlive: true },
        children: [
          {
            path: 'generic',
            component: () => import('@/views/current/generic'),
            meta: { title: '通用', icon: 'generic', keepAlive: true },
          },
          {
            path: 'feilin',
            component: () => import('@/views/current/feilin'),
            meta: { title: '菲林', icon: 'feilin', keepAlive: true },
          },
          {
            path: 'ipad',
            component: () => import('@/views/current/ipad'),
            meta: { title: '平板套', icon: 'ipad', keepAlive: true },
          },
          {
            path: 'headset',
            component: () => import('@/views/current/headset'),
            meta: { title: '耳机套', icon: 'headset', keepAlive: true },
          }
        ]
      },
      {
        path: '/design',
        name: 'design',
        component: () => import('@/views/design/index'),
        meta: { title: '约稿设计', icon: 'design', keepAlive: true }
      },
      {
        path: '/tbd',
        name: 'tbd',
        component: () => import('@/views/tbd/index'),
        meta: { title: '近期开放', icon: 'design', keepAlive: true }
      },
      { 
        path: '/center', 
        name: 'center',
        component: () => import('@/views/center/index'),
        meta: { title: '个人中心', icon: 'center', keepAlive: true }
      },

    ]
  },
]
// 根据后端menu表记录来匹配相对应的name, title,icon等字段的值,动态路由，需要配置用户权限才能访问
export const asyncRouterMap = [
  { path: '*', redirect: '/404', hidden: true }
]
const router = createRouter({
  history: createWebHistory(),
  routes: constantRouterMap
});
export default router;