import request from '@/utils/request'

// 条件查询获取会员列表
export function fetchAccounts(data) {
    return request({
        url: '/account/list',
        method: 'post',
        data: data
      })
}
// 更新会员信息
export function updateAccount(data) {
    return request({
        url: '/account/update',
        method: 'post',
        data: data
      })
}
// 删除会员信息
export function deleteAccount(param) {
    return request({
        url: '/account/delete',
        method: 'get',
        params: param
      })
}
// 新增会员
export function addAccount(data) {
    return request({
        url: '/account/add',
        method: 'post',
        data: data
      })
}

// 会员充值
export function rechargeAccount(params) {
    return request({
        url: '/account/recharge',
        method: 'get',
        params: params
      })
}

// 获取验证码
export function getVerifyCode(params) {
  return request({
    url: '/account/verify-code',
    method: 'get',
    params: params
  })
}

// 会员自行注册
export function register(data) {
  return request({
    url: '/account/register',
    method: 'post',
    data: data
  })
}

// 会员下载图片
export function downloadPicture(params) {
  return request({
    url: '/front/download-picture',
    method: 'get',
    params: params
  })
}

// 会员更新密码
export function updatePassword(data) {
  return request({
      url: '/account/update-password',
      method: 'post',
      data: data
  })
}
