<template>
  <section class="app-main">
    <router-view  :max="10" v-slot="{ Component }">
      <!-- <keep-alive :include="['currentLayout','original','design','center','detail','home', 'DetailLayout', 'video','videoDetail']"> -->
      <keep-alive>
        <component :is="Component"></component>
      </keep-alive>
    </router-view>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
  }
}
</script>
