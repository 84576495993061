import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, setName,removeName, removeToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        let request = {
          mobile: userInfo.mobile.trim(),
          password: userInfo.password.trim()
        }
        login(request).then(response => {
          const data = response.data
          const tokenStr = data.tokenHead+data.token
          // 会员类型，todo 如果vipType=1是超级会员则需要展示原创图的下载页，其他情况展示宣传广告页
          const vipType = data.vipType
          setToken(tokenStr)
          commit('SET_TOKEN', tokenStr)
          setName(request.mobile)
          commit('SET_NAME', request.mobile)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const data = response.data
          if (data.roles && data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', data.roles)
          } else {
            reject('getInfo: roles must be a non-null array !')
          }
          commit('SET_NAME', data.username)
          commit('SET_AVATAR', data.icon)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_NAME', '')
        removeToken()
        removeName()
        logout().then(() => {
          resolve()
        }).catch(error => {
          console.log("1111",error)
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_NAME', '')
        removeToken()
        removeName()
        resolve()
      })
    }
  }
}

export default user
