import { createApp } from 'vue'
import App from './App.vue'
import store from '@/store'
import router from "../src/router";
import ElementPlus from 'element-plus'
import vueWatermark from 'vue-watermark-directive'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import VueLazyLoad from 'vue3-lazyload'
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.use(router);
app.use(store);
const DEFAULT_CONFIG = {
  zIndex: 1000,
  font: '8px microsoft yahei',
  color: 'rgba(0,0,0,0.2)',
  content: '烨潮设计',
  rotate: 20,
  width: 40,
  height: 40,
  repeat: true
}
app.use(vueWatermark, DEFAULT_CONFIG);
app.use(ElementPlus, {
    locale: zhCn
})
app.use(VueLazyLoad, {})
app.mount("#app");