<template>
  <div>
    <div>
      <div class="bigbox">
        <el-input type="text" placeholder="输入材质名称" style="width:600px" v-model="keyword" clearable></el-input>
        <el-button type="primary" @click="searchMaterial" style="color: white; margin-left: 10px">搜索</el-button>
      </div>
      <div class="button-area">
        <span @click="fetchImages('详情')" style="display: none">详情首页</span>
        <span @click="fetchImages('详情页模板')" class="nav-btn" :class="{ activeButton: activeDetailTemplate }">详情页模板</span>
        <span @click="fetchImages('镜头孔位')" class="nav-btn" :class="{ activeButton: activeCameraHole }">镜头效果图</span>
        <span @click="fetchImages('详情页定制')" class="nav-btn" :class="{ activeButton: activeDetailCustomized }">详情页定制</span>
      </div>
    </div>
    <router-view  :max="10" v-slot="{ Component }">
      <keep-alive :include="['cameraHole, detailCustomized, detailHome, detailTemplate']">
        <component :is="Component"></component>
      </keep-alive>
    </router-view>
  </div>
</template>
  
<script>
export default {
  name: "detailLayout",
  data() {
    return {
      activeDetailTemplate: false,
      activeCameraHole: false,
      activeRealPhotography: false,
      activeDetailCustomized: false,
      keyword: '',
      pageNum: 1,
      pageSize: 10
    }
  },
  watch: {
    $route(to, from) { 
      this.activeDetailTemplate = to.path == '/detail/detailTemplate'
      this.activeCameraHole = to.path == '/detail/cameraHole'
      this.activeDetailCustomized = to.path == '/detail/detailCustomized'
    }
  },
  methods: {
    fetchImages(val) {
      this.activeDetailTemplate = val == '详情页模板';
      this.activeCameraHole = val == '镜头孔位';
      this.activeDetailCustomized = val == '详情页定制';
      if (val == '详情页模板') {
        this.$router.push('/detail/detailTemplate')
      } else if (val == "镜头孔位") {
        this.$router.push('/detail/cameraHole')
      } else {
        this.$router.push('/detail/detailCustomized')
      }
    },
    searchMaterial() {
      this.$router.push("/detail/detailHome?keyword=" + this.keyword)
    },
    search(material) {
      this.keyword = material
      this.searchMaterial()
    },
  },
}
</script>
  
<style scoped>
.button-area {
  text-align: center;
  margin-top: 30px;
}

.button-area span {
  margin-left: 10px;
  font-weight: 500;
  font-size: 18px;
  color: rgb(152, 149, 149);
  padding: 8px 14px;
  cursor: pointer;
}

.activeButton {
  border-radius: 20px;
  color: white !important;
  background-color: black;
  border: 2px solid black;
}

.bigbox {
  /* width: 800px; */
  /* height: 42px; */
  /* position: relative; */
  /* margin: auto; */
  text-align: center;
}

.nav-btn {
  border: 2px solid gray;
  border-radius: 20px;
}
</style>
  