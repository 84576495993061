<template>
  <div class="operation-wrapper">
    <el-header class="el-header">
      <el-menu style="height:60%;" :default-active="$route.meta.name" router mode="horizontal" text-color="#333333"
        active-text-color="#3366ff" @select="handleSelect">
        <el-menu-item style="font-size:20px;">
          <img src="@/assets/images/yechao.png" alt="烨潮原创" style="height:80px;width:80px;margin-left:15%;" />
        </el-menu-item>
        <el-menu-item style="font-size:20px;margin-left:45px " index="/home">首页</el-menu-item>
        <el-menu-item style="font-size:20px;margin-left:45px" index="/draw">临摹画图</el-menu-item>
        <el-menu-item style="font-size:20px;margin-left:45px" index="/original">原创图库</el-menu-item>
        <el-menu-item style="font-size:20px;margin-left:45px" index="/current">现图图库</el-menu-item>
        <el-menu-item style="font-size:20px;margin-left:45px" index="/design">二次创作</el-menu-item>
        <!-- <el-menu-item style="font-size:20px;margin-left:45px" index="/detail">详情设计</el-menu-item> -->
        <el-sub-menu index="1">
           <template #title><span style="font-size:20px;margin-left:20px">详情设计</span></template>
           <el-menu-item index="/detail">详情模板</el-menu-item>
           <el-menu-item index="/cameraDownload">镜头下载</el-menu-item>
         </el-sub-menu>
         <el-sub-menu index="2">
           <template #title><span style="font-size:20px;">摄影</span></template>
           <el-menu-item index="/photography">摄影实拍</el-menu-item>
           <el-menu-item index="/video">视频实拍</el-menu-item>
         </el-sub-menu>
        <span v-if="!token || token == ''" style="position: relative; left: 100px">
          <span style="margin-right: 20px; font-size: 20px; cursor: pointer;" @click="login">登录</span>
          <span style="font-size: 20px; cursor: pointer;" @click="register">注册</span>
        </span>
        <el-dropdown v-if="token" class="header-icon" @command="handleCommand">
          <span class="el-dropdown-link">
            <span style="position: relative;bottom: 10px;font-size: 18px;">{{ name }}</span>
            <svg t="1686139034699" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="2362" width="30" height="30">
              <path
                d="M509.3 511.3c-117.1 0-212.4-95.3-212.4-212.4S392.2 86.5 509.3 86.5c117.1 0 212.4 95.3 212.4 212.4s-95.3 212.4-212.4 212.4z m0-374.8c-89.5 0-162.4 72.9-162.4 162.4s72.9 162.4 162.4 162.4 162.4-72.9 162.4-162.4-72.9-162.4-162.4-162.4z"
                p-id="2363" fill="#515151"></path>
              <path
                d="M812.5 903.5H219.1c-29.3 0-56.7-13.1-75.1-35.9-18.4-22.8-25.4-52.4-19.1-81.1 19.6-90.5 68.9-172.6 138.7-231.4 34.9-29.4 74-52.3 116.1-68.1 43.6-16.4 89.4-24.6 136.2-24.6s92.6 8.3 136.2 24.6c42.1 15.8 81.1 38.7 116 68.1 69.8 58.7 119 140.9 138.7 231.4 6.2 28.7-0.7 58.3-19.1 81.1-18.5 22.8-45.9 35.9-75.2 35.9zM515.8 512.4c-40.8 0-80.7 7.2-118.6 21.5-36.8 13.8-70.9 33.8-101.4 59.5-61.4 51.7-104.7 124-122 203.7-3 13.9 0.3 28.1 9.2 39.1 8.9 11 22 17.3 36.2 17.3h593.4c14.1 0 27.3-6.3 36.2-17.3 8.9-11 12.2-25.3 9.2-39.1-17.3-79.7-60.7-152.1-122-203.7-30.5-25.7-64.7-45.7-101.4-59.5-38.1-14.3-78.1-21.5-118.8-21.5z"
                p-id="2364" fill="#515151"></path>
            </svg>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="toCenter">个人中心</el-dropdown-item>
              <el-dropdown-item command="changePwd">修改密码</el-dropdown-item>
              <el-dropdown-item command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-menu>
    </el-header>
    <el-container>
      <el-main class="el-main">
        <app-main></app-main>
      </el-main>
      <el-backtop :visibility-height="100" style="right: 30px;bottom: 150px;" target=".operation-wrapper .el-main"
        class="svg-content">
        <svg t="1682238990606" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="3591" width="100" height="100">
          <path
            d="M484.538555 297.401434 78.508052 703.407378c-11.784394 11.813047-11.784394 30.912034 0 42.724057 11.81714 11.81407 30.94171 11.81407 42.757826 0l386.211109-386.181433 379.830795 379.806235c11.453866 11.482519 30.039153 11.482519 41.491996 0 11.511171-11.453866 11.511171-30.039153 0-41.516556L534.372543 303.776631c-1.543146-1.539053-3.417843-2.296299-5.200442-3.412726-0.691755-0.935302-1.055029-2.085498-1.933025-2.962471-11.784394-11.784394-30.912034-11.784394-42.695405 0L484.538555 297.401434zM484.538555 297.401434"
            fill="#2c2c2c" p-id="3592"></path>
        </svg>
      </el-backtop>
    </el-container>
    <div class="fix-logo">
      <el-popover placement="left-start" title="" trigger="hover">
        <template #reference>
          <img src="../../assets/images/contact.jpg" alt="" style="max-height: 50px;max-width: 50px">
        </template>
        <img src="../../assets/images/contact.jpg" style="height: 200px;width: 200px">
      </el-popover>
      <p style="margin-top: 0;">扫码联系客服</p>
    </div>
    <el-dialog title="修改密码" v-model="changePwdVisible" width="35%">
      <el-form v-model="changePwdForm">
        <el-form-item label="账户名" label-width="120px" prop="mobile">
          <el-input v-model="changePwdForm.mobile" style="width: 250px"></el-input>
        </el-form-item>
        <el-form-item label="原密码" label-width="120px" prop="oldPwd">
          <el-input type="password" v-model="changePwdForm.oldPwd" style="width: 250px"></el-input>
        </el-form-item>
        <el-form-item label="新密码" label-width="120px" prop="newPwd">
          <el-input type="password" v-model="changePwdForm.newPwd" style="width: 250px"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" label-width="120px" prop="reNewPwd">
          <el-input type="password" v-model="changePwdForm.reNewPwd" style="width: 250px"></el-input>
        </el-form-item>
      </el-form>
      <span class="dialog-footer">
        <el-button @click="changePwdVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Navbar, AppMain } from './components'
import store from '@/store'
import { getName } from '@/utils/auth'
import { updatePassword } from '@/api/account'
export default {
  name: 'layout',
  components: {
    Navbar,
    AppMain,
  },
  data() {
    return {
      activeIndex: '',
      changePwdForm: {},
      changePwdVisible: false,
    }
  },
  computed: {
    ...mapGetters([
      'token',
      'name'
    ])
  },
  methods: {
    register() {
      // 跳转登陆页
      this.$router.push('/login')
    },
    login() {
      // 跳转登陆页
      this.$router.push('/login')
    },
    handleLogout() {
      store.dispatch('LogOut').then(() => {
        this.$router.push('/home')
      })
    },
    handleCommand(command) {
      if (command == "toCenter") {
        this.toCenter()
      } else if (command == "changePwd") {
        this.changePwd()
      } else {
        this.logout()
      }
    },
    handleDialogConfirm() {
      // 更改密码
      if (this.changePwdForm.newPwd != this.changePwdForm.reNewPwd) {
        this.$message({ message: '新密码与确认密码不一致', duration: 2000, type: 'error' })
        return
      }
      updatePassword(this.changePwdForm).then(resp => {
        this.$message({ message: '密码修改成功', duration: 2000, type: 'success' })
        this.changePwdVisible = false
      })
    },
    toCenter() {
      this.$router.push("/center")
    },
    changePwd() {
      this.changePwdVisible = true
      this.changePwdForm = {}
      this.changePwdForm.mobile = getName()
    },
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        this.$message({ message: '退出登录', duration: 1000, type: 'warning' })
      }).catch(err => {
        console.log("promise error", err)
      })
    },
    handleSelect(key, keyPath) {
        console.log(key, keyPath);
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "src/styles/mixin.scss";

.el-menu-item {
  padding: 0;
}

.nav-content {
  height: 120px;
  width: 100%;
  position: absolute;
}

.el-menu.el-menu--horizontal {
  border: none;
}

/* 标题颜色改变 */
>>>.el-menu--horizontal>.el-submenu .el-submenu__title {
  width: 14.3rem;
  color: white;
}

/* hover颜色改变 */
>>>.el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  color: rgb(102, 97, 97);
}

/* 消除动态添加的三角 */
>>>.el-icon-arrow-down:before {
  content: none;
}

/* 下拉宽度调整 */
>>>.el-menu-item {
  width: 5rem;
}

.fix-logo {
  text-align: center;
  position: fixed;
  right: 0px;
  bottom: 30px;
  width: 120px;
  height: 60px;
  padding: 8px;
}

.operation-wrapper {
  width: 100% !important;

  .el-header {
    height: 60px;
    text-align: center;
    margin-top: 30px;
    margin-left: 10%;
  }

  .el-main {
    padding: 0px 16px !important;
    height: calc(100vh - 100px); //100px为顶部header盒子高度
    overflow-y: auto;
  }
}

.svg-content {
  width: 60px;
  height: 60px;
  background: none;
  border: 3px solid black;
  padding: 2px 2px 4px 2px;
}

.header-icon {
  position: relative;
  left: 20%;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}

.el-menu--horizontal{
  :deep(.el-sub-menu__title) {
      border-bottom: none !important;
  }
 
}
</style>
