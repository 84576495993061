<template>
  <div>
    <div>
      <div class="button-area">
        <span @click="fetchImages('通用')" :class="{ activeButton: activeAll }">通用</span>
        <span @click="fetchImages('菲林')" :class="{ activeButton: activeFei }">菲林</span>
        <span @click="fetchImages('平板套')" :class="{ activeButton: activeIpad }">平板套</span>
        <span @click="fetchImages('耳机套')" :class="{ activeButton: activeHeadSet }">耳机套</span>
      </div>
    </div>
    <router-view :max="10" v-slot="{ Component }">
      <keep-alive :include="['generic', 'feilin', 'ipad', 'headset']">
        <component :is="Component"></component>
      </keep-alive>
    </router-view>

    <div style="text-align: center;margin-top: 20px;">
      <img  style="width: 1200px; height: 300px;" src="@/assets/images/免责声明.png" alt="免责声明" />
    </div>
  </div>
</template>

<script>
export default {
  name: "currentLayout",
  data() {
    return {
      activeAll: true,
      activeFei: false,
      activeIpad: false,
      activeHeadSet: false
    }

  },
  methods: {
    fetchImages(val) {
      this.activeAll = val == '通用';
      this.activeFei = val == '菲林';
      this.activeIpad = val == '平板套';
      this.activeHeadSet = val == '耳机套';
     
      if (val == '通用') {
        this.$router.push('/current/generic')
      } else if (val == '菲林') {
        this.$router.push('/current/feilin')
      } else if (val == '平板套') {
        this.$router.push('/current/ipad')
      } else {
        this.$router.push('/current/headset')
      }
    }
  },
}
</script>

<style scoped>
.button-area {
  text-align: center;
  margin-top: 30px;
}

.button-area span {
  margin-left: 10px;
  font-weight: 500;
  font-size: 18px;
  color: rgb(152, 149, 149);
  padding: 8px 14px;
  cursor: pointer;
}

.activeButton {
  border-radius: 20px;
  color: white !important;
  background-color: black;
}
.button-area span:hover {
   border-radius: 20px;
  color: white !important;
  background-color: black;
}
</style>