import request from '@/utils/request'

// 创建子账号
export function createAdmin(data) {
    return request({
        url: '/user/add',
        method: 'post',
        data: data
    })
}

// 用户登陆接口,新增返回类型vipType，返回当前登录会员用户的会员类型，vipType=1为超级会员
export function login(data) {
    return request({
        url: '/front/login',
        method: 'post',
        data: data
    })
}

// 获取当前登陆用户菜单权限基础信息
export function getInfo() {
    return request({
        url: '/user/info',
        method: 'get',
    })
}
// 退出
export function logout() {
    return request({
        url: '/user/logout',
        method: 'post'
    })
}
// 根据名称模糊查询用户列表
export function fetchList(data) {
    return request({
        url: '/user/list',
        method: 'post',
        data: data
    })
}
// 更新用户信息
export function updateAdmin(data) {
    return request({
        url: '/user/update',
        method: 'post',
        data: data
    })
}
// 删除用户
export function deleteAdmin(id) {
    return request({
        url: '/user/delete/' + id,
        method: 'post'
    })
}
// 更新用户状态
export function updateStatus(id, params) {
    return request({
        url: '/user/updateStatus/' + id,
        method: 'post',
        params: params
    })
}
// 分配角色
export function allocRole(data) {
    return request({
        url: '/user/role/update',
        method: 'post',
        data: data
    })
}

// 根据用户id获取所有的角色信息
export function getRoleByAdmin(id) {
    return request({
        url: '/user/role/' + id,
        method: 'get'
    })
}
